import {isEmpty, some, sortBy, values} from 'lodash'
import moment from 'moment'

import {
  areDatesAvailable,
  getEarliestDeliveryDate,
  getUnavailableDaysFromTimeSlots,
  prepareDateRange,
  selectMaterialOption
} from '../../Organisms/OrderIntake/utils'
import {getDateExceptions} from '../BulkCement/containers/DateFilter'
import {BulkOrderFormValues} from '../BulkOrdersFormValues'
import {DEFAULT_CUSTOMER_REFERENCE} from '../declarations/constants'
import {ShippingType} from '../declarations/OrderIntake.enums'
import {
  CapacityRequest,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderIntakeOptions,
  OrderRequest,
  SlotConfiguration
} from '../declarations/types'

const getMaterialOption = (
  newSelectedSite: OrderIntakeOption
): OrderIntakeMaterialOptionPayload[] => {
  const materialOptions = values(newSelectedSite.materials)
  const sortedMaterialOptions = sortBy(materialOptions, (item) => item[0]?.invalid)
  const firstMaterialOption = sortedMaterialOptions?.[0]
  let materialOption: OrderIntakeMaterialOptionPayload[] = []

  if (firstMaterialOption) {
    const allInvalid = firstMaterialOption.every((material) => material.invalid)
    materialOption = allInvalid
      ? firstMaterialOption
      : firstMaterialOption.filter((material) => !material.invalid)
  }

  return materialOption
}

export const updateOrdersBySite = (
  orderFields: OrderRequest[],
  newSelectedSite: OrderIntakeOption, // newSelectedSite
  prevSelectedSite: OrderIntakeOption,
  defaultOrderRequest: OrderRequest,
  slotConfiguration: SlotConfiguration[] | undefined
): OrderRequest[] => {
  return orderFields.map((orderField) => {
    // all materials
    const {deliveryDate, materialEnteredNumber} = orderField.payload
    const prevMaterialOption = prevSelectedSite.materials[materialEnteredNumber]

    const materialOption = getMaterialOption(newSelectedSite)
    const businessHours = materialOption[0].businessHours

    const dateRange = prepareDateRange(
      materialOption.map((item) => item.dateRange),
      businessHours,
      orderField.payload.shippingType === ShippingType.DELIVER
        ? newSelectedSite.materials
        : undefined,
      slotConfiguration
    )

    const {date, isDateChange} = newSelectedSite
      ? getEarliestDeliveryDate(deliveryDate, dateRange, materialOption[0].businessHours.timeZone)
      : {
          date: deliveryDate,
          isDateChange: false
        }

    const validMaterialOption = selectMaterialOption(date, materialOption)
    const prevMaterialEnteredNumber = selectMaterialOption(date, prevMaterialOption)?.material
      .materialEnteredNumber
    const newMaterialEnteredNumber = validMaterialOption?.material.materialEnteredNumber
    const newMaterialNumber = validMaterialOption?.material.materialNumber
    const newMaterialDescription = validMaterialOption?.material.materialDescription
    const newPlantName = validMaterialOption?.plant.plantName
    const newPlantNumber = validMaterialOption?.plant.plantNumber
    const customerReference = validMaterialOption?.customerReference
    const isMaterialHighlighted = prevMaterialEnteredNumber !== newMaterialEnteredNumber

    const isNoDatesAvailable = !areDatesAvailable(
      dateRange,
      getDateExceptions(materialOption[0].businessHours, dateRange),
      getUnavailableDaysFromTimeSlots(
        businessHours,
        slotConfiguration,
        materialOption[0].plant.configurableSlots
      ).map((s) => moment(s))
    )

    return {
      ...orderField,
      isMaterialHighlighted,
      isDateChange,
      isNoDatesAvailable,
      initialDeliveryDate: date,
      payload: {
        ...orderField.payload,
        siteNumber: newSelectedSite.shippingAddress.siteNumber,
        deliveryDate: date,
        materialEnteredNumber:
          newMaterialEnteredNumber ?? defaultOrderRequest.payload.materialEnteredNumber,
        materialNumber: newMaterialNumber ?? defaultOrderRequest.payload.materialNumber,
        materialDescription:
          newMaterialDescription ?? defaultOrderRequest.payload.materialDescription,
        plantName: newPlantName ?? defaultOrderRequest.payload.plantName,
        plantNumber: newPlantNumber ?? defaultOrderRequest.payload.plantNumber,
        businessLineCode: validMaterialOption?.material.businessLine ?? '',
        capacity: {
          quantity: validMaterialOption?.truckCapacity.capacity,
          quantityType: validMaterialOption?.material.quantityType
        } as unknown as CapacityRequest,
        customerReference: customerReference || DEFAULT_CUSTOMER_REFERENCE,
        contractNumber: validMaterialOption?.material.contractNumber ?? '',
        contractItemPositionNumber: validMaterialOption?.material.contractItemPositionNumber ?? '',
        contact: {
          ...orderField.payload.contact,
          mainPhone: !isEmpty(newSelectedSite.contact.mainPhone)
            ? newSelectedSite.contact.mainPhone
            : defaultOrderRequest.payload.contact.mainPhone,
          ...(orderField.payload.shippingType === ShippingType.DELIVER
            ? {
                backupPhone: !isEmpty(newSelectedSite.contact.backupPhone)
                  ? newSelectedSite.contact.backupPhone
                  : defaultOrderRequest.payload.contact.backupPhone
              }
            : {})
        }
      }
    }
  })
}

type Resp = {
  selectedSite: OrderIntakeOption
  isOrderRequestConfirmed: boolean
  orders: OrderRequest[]
}

// eslint-disable-next-line import/no-default-export
export default function (
  formData: Omit<BulkOrderFormValues, 'orders'>,
  orderFields: OrderRequest[],
  siteOptions: OrderIntakeOptions,
  siteNumber: string,
  defaultOrderRequest: OrderRequest,
  slotConfiguration: SlotConfiguration[] | undefined
): Resp {
  const previousSiteNumber = formData.selectedSite.shippingAddress.siteNumber
  if (previousSiteNumber === siteNumber)
    return {
      selectedSite: formData.selectedSite,
      isOrderRequestConfirmed: formData.isOrderRequestConfirmed,
      orders: orderFields
    }

  const newSelectedSite = siteOptions[siteNumber]
  const newOrders = updateOrdersBySite(
    orderFields,
    newSelectedSite,
    formData.selectedSite,
    defaultOrderRequest,
    slotConfiguration
  )
  const isOrderRequestConfirmed = !some(newOrders, (o) => o.isMaterialHighlighted || o.isDateChange)

  return {
    selectedSite: newSelectedSite,
    orders: newOrders,
    isOrderRequestConfirmed
  }
}
